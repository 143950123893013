import { Button, ButtonGroup, Layout, LegacyCard, LegacyStack, useIndexResourceState } from "@shopify/polaris";
import { ExportMinor } from "@shopify/polaris-icons";
import { IBillCSV, IBillWithPurchStAndCustomerRelationship } from "@smartrr/shared/entities/Billing";
import React, { useCallback, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { TablePage } from "@vendor-app/app/_sharedComponents/TablePageWrapper";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  SelectionType,
  TypedTableColumnValue,
  usePolarisTypedTable,
} from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";
import { useTableHandlers } from "@vendor-app/app/_sharedComponents/TypedTable/useTableHandlers";
import { cancelBillsInBulk, loadBills, retryBillsInBulk } from "@vendor-app/app/_state/actionCreators/bill";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import {
  HeaderContainer,
  PaginationContainer,
  checkIfBillCanBeRetried,
} from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import { createAndDownloadCsv } from "@vendor-app/utils/createAndDownloadCsv";
import { exportTransactionsToCsvRows, parseBillsForCSV } from "@vendor-app/utils/exportTransactionsToCsvRows";
import { isRowSelected } from "@vendor-app/utils/isIndexTableRowSelected";
import { mapArrayEntityWithDelay } from "@vendor-app/utils/mapArrayEntityWithDelay";
import { navigateToSubscriptionDetails } from "@vendor-app/utils/navigateWithShopInQuery";
import { redirectToShopify } from "@vendor-app/utils/redirectToShopify";

import {
  TransactionHistoryTableColumnKeyType,
  ValueSelectorForTransactionHistoryFilter,
  billSearchOptions,
  parseBillsForIndexTable,
  transactionHistoryColumns,
} from "./libs";
import { ButtonBoldText } from "../../_sharedComponents/ButtonBold";
import { TableSearch } from "../../_sharedComponents/TableSearch";
import { getQueryParamsForFilters } from "../../_sharedComponents/TableSearch/libs";
import { checkIfBillCanBeCanceled } from "../AdminSubscriptionDetailsRoute/libs/utils/checkIfBillCanBeCanceled";

export const AdminBillsRoute = () => {
  const { addToast } = useToast();
  const { data, totalCount, isLoading, totalPages, pageSize } = useSmartrrVendorSelector(state => state.bills);
  const activeOrg = useActiveOrganizationSelector();
  const dispatch = useSmartrrVendorDispatch();
  const isMobile = useMediaQuery({
    maxWidth: 900,
  });

  const { Table, Pagination, Filter, Sorting, Columns } =
    usePolarisTypedTable<TransactionHistoryTableColumnKeyType>();

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(
    data.map(bill => ({ id: String(bill.id) }))
  );

  const [tableProps, tableHandlers] = useTableHandlers(
    "createdDate",
    "DESC",
    undefined,
    undefined,
    "emailOrName"
  );

  const [transactionHistoryColumnsState, setTransactionHistoryColumnsState] = useState(transactionHistoryColumns);
  const [exportToCSVIsLoading, setExportToCSVIsLoading] = useState<boolean>(false);

  const retryBills = async (billIds: string[]) => {
    await dispatch(retryBillsInBulk({ ids: billIds, addToast, tableProps }));
  };
  const cancelBills = async (billIds: string[]) => {
    await dispatch(cancelBillsInBulk({ ids: billIds, addToast, tableProps }));
  };

  const exportToCsvWithLoadingState = useCallback(
    async (bills?: IBillWithPurchStAndCustomerRelationship[]) => {
      if (activeOrg) {
        setExportToCSVIsLoading(true);
        try {
          let rows;

          if (bills?.length) {
            rows = await mapArrayEntityWithDelay<IBillWithPurchStAndCustomerRelationship, IBillCSV>({
              items: bills,
              chunkSize: 250,
              waitInterval: 500,
              filterFunction: parseBillsForCSV,
            });
          } else {
            rows = await exportTransactionsToCsvRows({
              filterIn: getQueryParamsForFilters(tableProps, tableProps?.filter)?.filterIn,
            });
          }
          rows && createAndDownloadCsv(rows, "Transaction history");
        } catch {
          addToast("Error exporting to CSV");
        } finally {
          setExportToCSVIsLoading(false);
        }
      }
    },
    [activeOrg, tableProps]
  );

  const handleExportAction = () => {
    exportToCsvWithLoadingState(
      allResourcesSelected ? undefined : data.filter(bill => selectedResources.includes(String(bill.id)))
    );
    handleSelectionChange(SelectionType.All, false);
  };

  const promotedBulkActions = [
    checkIfBillCanBeCanceled(data, selectedResources)
      ? {
          content: "Cancel retry",
          onAction() {
            cancelBills(
              data
                .filter(bill => selectedResources.includes(String(bill.id)) && bill.status === "pending")
                .map(bill => String(bill.uniqueId))
            );
            handleSelectionChange(SelectionType.All, false);
          },
        }
      : null,
    checkIfBillCanBeRetried(data, selectedResources)
      ? {
          content: "Retry",
          onAction() {
            retryBills(
              data
                .filter(
                  bill =>
                    selectedResources.includes(String(bill.id)) &&
                    (bill.status === "failed" || bill.status === "pending")
                )
                .map(bill => String(bill.uniqueId))
            );
            handleSelectionChange(SelectionType.All, false);
          },
        }
      : null,
    {
      content: "Export to CSV",
      onAction: handleExportAction,
      isLoading: exportToCSVIsLoading || isLoading,
      disabled: isLoading,
    },
  ];

  const bulkActions = useMemo(() => {
    const actions: { content: string; onAction: () => void }[] = [];
    const selectedBill = data.find(bill => String(bill.id) === selectedResources[0]);
    const cpsShopifyId = selectedBill?.st?.shopifyId;
    const customerShopifyId = selectedBill?.st?.custRel?.shopifyId;
    const orderShopifyId = selectedBill?.order?.shopifyId;

    if (!selectedBill || selectedResources.length > 1) {
      return [];
    }

    if (customerShopifyId && cpsShopifyId) {
      actions.push({
        content: "View subscription details",
        onAction: () => navigateToSubscriptionDetails(cpsShopifyId, true),
      });
    }

    if (customerShopifyId && activeOrg) {
      actions.push({
        content: "View customer in Shopify",
        onAction: () => redirectToShopify(activeOrg, "customers", customerShopifyId),
      });
    }

    if (orderShopifyId && activeOrg) {
      actions.push({
        content: "View order in Shopify",
        onAction: () => redirectToShopify(activeOrg, "orders", orderShopifyId),
      });
    }

    return actions;
  }, [activeOrg, data, selectedResources]);

  return (
    <React.Fragment>
      <TablePage
        title="Transactions"
        primaryAction={
          <LegacyStack vertical={isMobile} alignment="center">
            <ButtonBoldText>
              <Button
                plain
                monochrome
                removeUnderline
                icon={ExportMinor}
                loading={exportToCSVIsLoading}
                onClick={() => exportToCsvWithLoadingState()}
              >
                Export to CSV
              </Button>
            </ButtonBoldText>
          </LegacyStack>
        }
      >
        <Layout>
          <Layout.Section>
            <LegacyStack spacing="loose" vertical>
              <LegacyCard>
                <LegacyCard.Section flush>
                  <HeaderContainer>
                    <div style={{ flex: 1 }}>
                      <TableSearch
                        key="autocomplete"
                        tableProps={tableProps}
                        selectOptions={billSearchOptions}
                        loadData={loadBills}
                        setFilter={tableHandlers.setFilter}
                        setPageNumber={tableHandlers.setPageNumber}
                      />
                    </div>

                    <ButtonGroup segmented>
                      <Filter
                        columns={transactionHistoryColumns}
                        filter={tableProps.filter}
                        setFilter={tableHandlers.setFilter}
                        filterComponent={(field, value, handleSetFilter) => (
                          <ValueSelectorForTransactionHistoryFilter
                            field={field}
                            value={value}
                            setValue={newValue => handleSetFilter("update", field, newValue)}
                            currency={activeOrg?.shopifyStoreData?.currency}
                          />
                        )}
                      />
                      <Columns
                        columns={transactionHistoryColumnsState}
                        addToast={addToast}
                        setDisabledColumns={setTransactionHistoryColumnsState}
                      />

                      <Sorting
                        columns={transactionHistoryColumns}
                        orderByField={tableProps.orderByField}
                        orderByValue={tableProps.orderByValue}
                        setOrderByField={tableHandlers.setOrderByField}
                        setOrderByValue={tableHandlers.setOrderByValue}
                      />
                    </ButtonGroup>
                  </HeaderContainer>

                  <Table
                    selectable
                    columns={transactionHistoryColumnsState}
                    data={parseBillsForIndexTable(data, activeOrg)}
                    loading={isLoading}
                    emptyStateText="No transaction history"
                    resourceName={{
                      singular: "transaction",
                      plural: "transactions",
                    }}
                    itemCount={totalCount < pageSize ? totalCount : pageSize}
                    bulkActions={bulkActions.filter((x): x is any => x != null)}
                    promotedBulkActions={promotedBulkActions.filter((x): x is any => x != null)}
                    sortable={Object.values(transactionHistoryColumnsState)
                      .filter(v => !v.disabled)
                      .map(v => v.sorting)}
                    selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
                    sortColumnIndex={Object.entries(transactionHistoryColumnsState).findIndex(
                      ([key, value]) => value.paginatedValue === tableProps.orderByField && !value.disabled
                    )}
                    sortDirection={tableProps.orderByValue === "ASC" ? "ascending" : "descending"}
                    onSort={(headingIndex: number, direction: "ascending" | "descending") => {
                      tableHandlers.setOrderByField(
                        Object.entries<TypedTableColumnValue>(transactionHistoryColumnsState).filter(
                          ([key, value]) => !value.disabled
                        )[headingIndex][0]
                      );
                      tableHandlers.setOrderByValue(direction === "ascending" ? "ASC" : "DESC");
                    }}
                    onSelectionChange={(selectionType, toggleType, selection) => {
                      handleSelectionChange(selectionType, toggleType, selection);
                    }}
                    isRowSelected={id => isRowSelected(id, selectedResources)}
                  />
                </LegacyCard.Section>
                <LegacyCard.Section subdued>
                  <PaginationContainer>
                    <Pagination
                      hasNext={totalPages > tableProps.pageNumber}
                      hasPrevious={tableProps.pageNumber != 1}
                      label={`Showing ${tableProps.pageNumber} of ${totalPages}`}
                      onNext={() => tableHandlers.setPageNumber(p => p + 1)}
                      onPrevious={() => tableHandlers.setPageNumber(p => p - 1)}
                    />
                  </PaginationContainer>
                </LegacyCard.Section>
              </LegacyCard>
            </LegacyStack>
          </Layout.Section>
        </Layout>
      </TablePage>
    </React.Fragment>
  );
};
