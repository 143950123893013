import { CurrencyCode } from "@smartrr/shared/currencyCode";
import { IBillCSV, IBillWithPurchStAndCustomerRelationship } from "@smartrr/shared/entities/Billing";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { capitalizeFirstLetter } from "@smartrr/shared/utils/capitalizeFirstLetter";
import { formatMoney } from "@smartrr/shared/utils/formatMoney";

import { getBills } from "@vendor-app/app/_state/actionCreators/bill";

import { getQueryObj } from "./getQueryObject";
import { mapArrayEntityWithDelay } from "./mapArrayEntityWithDelay";

export interface IExportCSVFilters {
  startDate?: string;
  endDate?: string;
  filterIn?: { [key: string]: string[] };
  filterLike?: { [key: string]: string };
  orderByField?: string;
}

export async function exportTransactionsToCsvRows(filters?: IExportCSVFilters): Promise<IBillCSV[]> {
  const startDate = filters?.startDate;
  const endDate = filters?.endDate;

  const orderByField = filters?.orderByField ?? "billingDate";
  const filterIn = filters?.filterIn;
  const filterLike = filters?.filterLike;

  const allData: IBillWithPurchStAndCustomerRelationship[] = [];
  let pageNumber = 0;
  let totalPages = 1;

  while (pageNumber < totalPages) {
    const page = await getBills(getQueryObj(pageNumber, startDate, endDate, filterIn, filterLike, orderByField));

    if (!page) {
      throw new Error("Unknown error");
    }

    if (page.type === "error") {
      throw new Error(page.message);
    }

    allData.push(...page.body.data);
    pageNumber = page.body.pageNumber;
    totalPages = page.body.totalPages;
    pageNumber++;
  }

  return await mapArrayEntityWithDelay<IBillWithPurchStAndCustomerRelationship, IBillCSV>({
    items: allData,
    chunkSize: 250,
    waitInterval: 500,
    filterFunction: parseBillsForCSV,
  });
}

export function parseBillsForCSV(bill: IBillWithPurchStAndCustomerRelationship): IBillCSV {
  const createdDate = ISODateString.fromString(bill.createdDate);
  const updatedDate = ISODateString.fromString(bill.updatedDate);

  const createdDateStr = `${createdDate.toLocaleString({
    day: "2-digit",
    month: "short",
    year: "numeric",
  })} at ${createdDate.toLocaleString({
    hour: "numeric",
    hour12: true,
  })}`;

  const updatedDateStr = `${updatedDate.toLocaleString({
    day: "2-digit",
    month: "short",
    year: "numeric",
  })} at ${updatedDate.toLocaleString({
    hour: "numeric",
    hour12: true,
  })}`;

  return {
    "Transaction ID": bill.id,
    Customer: `${bill.st.custRel?.firstName ?? ""}${bill.st.custRel?.lastName ?? "-"}`,
    "Initial bill attempt": createdDateStr,
    "Last update time": updatedDateStr,
    Status: capitalizeFirstLetter(bill.status),
    Total: formatMoney(bill.billAmount, bill.billCurrency as CurrencyCode),
    "Retry count": bill.retryCount,
    "Failure reason": bill.error ?? "—",
  };
}
